<template>
  <div class="maxBox">
    <div class="header">
      <div></div>
      <div class="work_bench">工作台</div>
      <!-- <div class="avatar"> -->
      <el-avatar icon="el-icon-user-solid" :src="myself.avatar"></el-avatar>
      <!-- </div> -->
    </div>
    <div class="work_box">
      <div class="wo_box">
        <img class="work_bc_img" src="../../assets/images/work_phone_background.png" alt />
        <div class="work_module" v-for="(item,index) in workList" :key="index">
          <h3>{{item.title}}</h3>
          <div style="margin:0 20px;display:flex">
            <div
              style="text-align:center;width:25%"
              v-for="(item,index) in workBoxList"
              :key="index"
              @click="handleWork(item)"
            >
              <img :src="require('../../assets/images/' + item.img + '.png')" alt />
              <span>{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from '../../components/global.vue'
export default {
  data () {
    return {
      userSite: user.userSite,
      myself: {
        avatar: '',
      },//个人信息
      workList: [
        {
          title: "我的",
        }
      ],
      workBoxList: [
        {
          img: 'work_huibao',
          text: '工作汇报',
          url: '/phone/work_bench/report_list',
        },
        {
          img: 'work_huibao',
          text: '汇报管理',
          url: '',
        }
      ]
    }
  },
  created () {
    this.$nextTick(() => {
      this.getMyself()
    })
  },
  methods: {
    getMyself () {
      this.axios.get('/api/member/view_self').then((res) => {
        this.myself = res.data
        this.myself.avatar = this.userSite + res.data.avatar
        console.log(this.myself.avatar, 'res')
      })
    },
    handleWork (item) {
      console.log(item, 'index')
      this.$router.push(item.url)
      // alert('暂未开放')
    },
  },
}
</script>

<style scoped>
.maxBox {
  background: #ebeff3;
  height: 100%;
}
.header {
  width: 100%;
  height: 43px;
  background: #0081ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.work_bench {
  color: #fff;
  transform: translateX(15px);
}
.work_box {
  width: 100%;
}
.wo_box {
  margin: 12px;
}
.work_bc_img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.work_module {
  width: 100%;
  background: #fff;
  margin-top: 15px;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 20px;
}
.work_module h3 {
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #000000;
  margin: 20px 0 20px 20px;
}
.work_module span {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #000000;
}
.work_module img {
  display: block;
  width: 40px;
  margin: 0 auto;
}
</style>